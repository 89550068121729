import "focus-visible/dist/focus-visible";
import "quicklink/dist/quicklink";
import svg4everybody from 'svg4everybody';
import "./vendors/_vendors.modernizr";
import vhCheck from 'vh-check';

svg4everybody();

const vh_check = vhCheck();

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
